import { FormControl, Grid, TextField, Button, Container, Typography  } from '@mui/material';
import { useFormik } from 'formik';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import * as React from 'react';
import { useRef, useState, useEffect } from "react"
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import Navbar from "../navBar/Navbar"
import NavbarListDrawer from '../navBar/NavListDrawer';
import axios from "../../api/axios";
import RegisterBeneficiary from '../RegisterBeneficiary/RegisterBeneficiary';
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import CssBaseline from '@mui/material/CssBaseline';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { useTheme, lighten } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppNavBar from "../AppBar/AppNavBar";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddFamilyModal from '../Modals/AddFamilyModal';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';



const backendUrl = process.env.REACT_APP_BACKEND_URL;
const VERIFY_URL = `${backendUrl}/patient/verify`;
const VerifyBeneficiary = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [success, setSuccess] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [token, setToken] = useState();
    const [email, setEmail] = useState();
    const [dni, setDni] = useState();
    const [fichaId, setfichaId ] = useState();
    const [institucionId, setInstitucionId] = useState();
    const [isNavbarListDrawerVisible, setIsNavbarListDrawerVisible] = useState(true);
    const [id, setId] = useState();
    const [patientFamily, setPatientFamily] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [finishLoading, setFinishLoading] = useState(false)

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  
    const marginLeftStyle = isSmallScreen || isMediumScreen || isExtraSmallScreen  ? 10 : {};


    const initialValues = {
      email: email,
      dni: '',
    };
  
    const validationSchema = Yup.object({
      dni: Yup.string()
        .required('El DNI es obligatorio')
        .matches(/^\d{6,8}$/, 'El DNI debe tener 8 dígitos numéricos'),
    });

    const handleDniChange = (event) => {
      const inputValue = event.target.value;
      if (/^\d*$/.test(inputValue)) {
        formik.handleChange(event);
      }
    };

    const deletFamily = (id) => {
      Swal.fire({
        title: '¿Estás seguro?',
        text: "No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, borrar!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await axios.delete(`${backendUrl}/user/unlink/patient`, {
              data: {linked_patient_id: id, user_id: Number(Cookies.get("id"))},
              headers: {
                Authorization: token,
              },
            });
            Swal.fire(
              'Eliminado!',
              'El paciente ha sido eliminado.',
              'success'
            )
            getPatients();
          } catch (error) {
            console.error(error);
          }
        }
      })
    }

    useEffect(() => {
      const storedToken = Cookies.get("token");
      const storedEmail = Cookies.get("email");
      const storedId = Cookies.get("id");
      if (storedToken && storedEmail) {
        setToken(storedToken);
        setEmail(storedEmail);
        setId(storedId);
      }
    }, []);

    useEffect(() => {
      if (id) {
        getPatients();
      }
    }, [id]);

    const getPatients = async () => {
      try {
        const response = await axios.get(`${backendUrl}/user/${id}/patients`, {
          headers: {
            Authorization: Cookies.get("token"),
          },
        });
        setPatientFamily(response.data.data);
        setFinishLoading(true);
      } catch (error) {
        console.error(error);
        setFinishLoading(true);
      }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: lighten('#1C7D89', 0.5),
        color: theme.palette.common.white,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      // hide last border
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    }));


    const onSubmit = () => {
      return setOpenModal(true);
    }

    const handleFamilyLink = (success, dni, modalOpen, fichaId, institucionId) => {
      setSuccess(success);
      setDni(dni);
      setModalOpen(modalOpen);
      setfichaId(fichaId);
      setInstitucionId(institucionId);
      setOpenModal(false);
    
    }



  
    const onSubmitt = async (e) => {
        try {
            const response = await axios.post(VERIFY_URL,
                JSON.stringify({email: email, dni :Number(formik.values.dni)}),
                {
                  headers:{
                    Authorization: token,
                    'Content-Type': 'application/json'
                  }
                })
                setDni(formik.values.dni)
                setSuccess(true)
                setfichaId(response.data.data.id)
                setInstitucionId(response.data.data.institution_id)
                setModalOpen(true)
        }
        catch (err) {
          Swal.fire({
            title: 'Error al encontrar paciente',
            icon: 'error',
          })
        }
    };

    
  
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit,
    });
  
    return (
        <>

        <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppNavBar />


        <Box
          component="main"
          sx={{
            mt: marginLeftStyle,
             textAlign: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Card variant="outlined"
            sx={{
              width: { xs: "100%", sm: "70%" },
              ml: { xs: "0%", sm: "15%" },
              mt: !isSmallScreen ? "10%" : "20%",
                mb: "auto",
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                transform: 'translateZ(0)',
                
            }}>
          <CardContent>
          <Box display="flex" flexDirection="column" height="100%">
  <Box mb="auto">
  <Typography variant="h5" sx={{ mt: 3, mb: 3 }}>
            GRUPO FAMILIAR
        </Typography>
        {
        (patientFamily.length === 0) &&(
          <Typography variant="h5" color="text.secondary" sx={{ mt: 2 }}>
                  No hay pacientes vinculados a esta cuenta
                </Typography>
        )
      } 

        <form onSubmit={formik.onSubmit}>
          <Grid container justifyContent="center" >
            {
          patientFamily.length > 0 && 
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell align="right">DNI</StyledTableCell>
                <StyledTableCell align="right">Acción</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientFamily.map((row, index) => (
                <StyledTableRow key={row.name}>
                  <StyledTableCell component="th" scope="row">
                    {index === 0 && row.name + " " + row.surname + " (yo)"}
                    {index !== 0 && row.name + " " + row.surname}

                  </StyledTableCell>
                  <StyledTableCell align="right">{row.dni}</StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton onClick={() => deletFamily(row.id)}>
                    {index !== 0 && <DeleteIcon />}
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }
        
          </Grid>
        </form>
  </Box>
  <Box display="flex" justifyContent="flex-end" sx={{mt:5}}>
    <Grid item xs={12}>
      <Button variant="contained" color="primary" onClick={onSubmit}>
        Agregar familiar
      </Button>
      
    </Grid>
  </Box>
</Box>
        
        </CardContent>
        </Card>
          </Box>
        </Box>

        {success && <RegisterBeneficiary email={email} dni={dni} open={modalOpen} fichaId={fichaId} institucionId={institucionId} onClose={() => { setSuccess(false) }}/>}
        {openModal && finishLoading  && <AddFamilyModal show={true} email={email} token={token} onFamilyLink={handleFamilyLink} onClose={() => setOpenModal(false)}/>}
        
        </>
      
    );
}

export default VerifyBeneficiary