
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Logo from '../../assets/Logo.png';
import NavListDrawer from '../navBar/NavListDrawer';
import HospitalItalItalianoApp from '../../assets/HospitalItalianoAppBar.jpg';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Cookies from "js-cookie";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawerr from '@mui/material/Drawer';
import { margin } from '@mui/system';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import AppImage from '../../assets/AppBar.jpg';



const drawerWidth = 240;


const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: '#222d32',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#222d32',
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  position: 'fixed', // Asegura que el AppBar se mantenga fijo
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // Ajustes cuando el Drawer está abierto, si es necesario
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const  AppNavBar = (props) => {
  const { isLogin = false } = props
  const [token, setToken] = React.useState(null);
  const [fullName, setFullName] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));



  function cerrarSesion() {
    Cookies.remove('token');
    window.location.href = "/login"
}
  const hideFullName = useMediaQuery('(min-width:1000px) and (min-height:750px)');
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hanldleClick = () => {
    navigate('/myprofile');
  }

  

  // Obtener de las cookies el name y surname del usuario si está logueado
  React.useEffect(() => {
    if (!isLogin) {
      const storedToken = Cookies.get("token");
      const name = Cookies.get("name");
      const surname = Cookies.get("lastname");
      if(name && surname) setFullName(`${name} ${surname}`);
    }
  }, [setToken, token]);
  const shouldOpenAppBar = !isLogin && open 

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];
const [mobileOpen, setMobileOpen] = React.useState(false);
const handleDrawerToggle = () => {
  setOpen((prevState) => !prevState);
};

const drawerStyle = {
  backgroundColor: '#222d32',
};

  return (
    <>
    
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      { !isLogin &&
          <AppBar position="fixed" sx={{ backgroundImage: `url(${AppImage})`, backgroundSize: 'cover', backgroundPosition: 'bottom', height:90 }}>
          <Toolbar sx={{ minHeight: 65 }}>
          </Toolbar>
        </AppBar>
          
        }
      {
        !isLogin &&

        <AppBar position="fixed" open={shouldOpenAppBar} sx={{ mt: 12, backgroundColor: "transparent", boxShadow: 'none' }} >
        <Toolbar>
          
          {
            !isLogin &&
            <>
            
            <IconButton
            color= {!isSmallScreen ? 'inherit' : '#2c8d99'}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...((shouldOpenAppBar) && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          
          </>
          }
          {
            !isSmallScreen &&
            <Avatar alt="Remy Sharp" src={Logo} sx={{ mr: 2, width: 200, height: 60, overflow: 'visible', ml: 1, }} />

          }
            
          <div style={{marginLeft: 'auto', display: 'flex', alignItems: 'center'}}>
          
          {
            fullName && !isLogin && hideFullName &&
            <Button sx={{color: 'black'}} onClick={hanldleClick}>
            {fullName}
          </Button>
          }
          {
            !isLogin &&
            <>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="black"
              >
                <AccountCircleOutlinedIcon />
              </IconButton>
          <Menu
          sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                
                <MenuItem onClick={hanldleClick}>MIS DATOS</MenuItem>
                
              </Menu>
              </>
          }
          
        </div>
        </Toolbar>
      </AppBar>}
      
    </Box>
    {
        (!isSmallScreen && !isLogin) &&
        (<Drawer variant="permanent" open={open} >
        <DrawerHeader >
          <IconButton onClick={handleDrawerClose} sx={{ color: 'white' }}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{mt: open ? "40%" : "120%"}}>
        <NavListDrawer handleDrawerOpen={() => setOpen(true)} open={open}/>

        </Box>
        <div style={{ marginTop: 'auto' }}>
      <nav aria-label="secondary mailbox folders" >
        <List>
          <ListItem disablePadding>
          <Tooltip title="Cerrar Sesión" TransitionComponent={Zoom} placement="right">
            <ListItemButton
            onClick={cerrarSesion}
            >
              <ListItemIcon sx={{ color: 'white' }}>
              <LogoutIcon />
            </ListItemIcon>
              <ListItemText primary="Cerrar Sesión" sx={{ color: 'white' }}/>
            </ListItemButton>
          </Tooltip>
          </ListItem>
                </List>
            </nav>
      </div>
      </Drawer>)
      }
    {(isSmallScreen && !isLogin) &&(
    <Drawerr
    PaperProps={{
      style: drawerStyle,
    }}
      anchor='left'
      open={open}
      onClose={handleDrawerClose}
    >
      
      <Divider />
      <Box sx={{mt:'100%'}}>
        <NavListDrawer handleDrawerOpen={() => setOpen(true)} open={open}/>

        </Box>
        <div style={{ marginTop: 'auto' }}>
      <nav aria-label="secondary mailbox folders" >
        <List>
          <ListItem disablePadding>
          <Tooltip title="Cerrar Sesión" TransitionComponent={Zoom} placement="right">
            <ListItemButton
            onClick={cerrarSesion}
            >
              <ListItemIcon sx={{ color: 'white' }}>
              <LogoutIcon />
            </ListItemIcon>
              <ListItemText primary="Cerrar Sesión" sx={{ color: 'white' }}/>
            </ListItemButton>
          </Tooltip>
          </ListItem>
                </List>
            </nav>
      </div>
    </Drawerr>
  )}
    </>
  );
}

export default AppNavBar;