import { useRef, useState, useEffect } from "react";
import { Box, Grid, TextField, Typography,  } from "@mui/material"
import axios from "../../api/axios";
import Cookies from "js-cookie";
import Navbar from "../navBar/Navbar"
import NavbarListDrawer from '../navBar/NavListDrawer';
import CssBaseline from '@mui/material/CssBaseline';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppNavBar from "../AppBar/AppNavBar";



const backendUrl = process.env.REACT_APP_BACKEND_URL;

const MyProfile = () => {

    const [datos, setDatos] = useState({})
    const [token, setToken] = useState();
    const [dni, setDni] = useState();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  
    const marginLeftStyle = isSmallScreen || isMediumScreen || isExtraSmallScreen || isLargeScreen ? { ml: 7 } : {};


    useEffect(() => {
        const storedToken = Cookies.get("token");
        const storedDni = Cookies.get("dni");
        if (storedToken) {
          setToken(storedToken);
        }
        if (storedDni) {
          setDni(storedDni);
        }
      }, [setToken]);

    async function getPatient() {
        try {
          const response = await axios.get(`${backendUrl}/patient/${dni}`, {
            headers: {
              Authorization: token,
            },
          });
          if (response.status === 200) {
            setDatos({
              name: response.data.name,
              surname: response.data.surname,
              dni: response.data.dni,
              mail: response.data.mail,
              street: response.data.street,
              telephone: response.data.telephone,
              cellphone: response.data.cellphone,
            });
          }

        } catch (error) {
          setDatos({});
        }
      }

      useEffect(() => {
        if(token){
          getPatient();
        }
      }, [token]);

    return (
        <>
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppNavBar />
        <Box
          component="main"
          sx={{
             textAlign: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
        <Card variant="outlined"
            sx={{
              width: { xs: "100%", sm: "70%" },
              ml: { xs: "0%", sm: "15%" },
              height: 'auto',
                mt: "5%",
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                transform: 'translateZ(0)',
            }}
        >
          <CardContent>

                <Typography variant="h5"
                                sx={{
                                    mt: 3,
                                    borderRadius: '4px', 
                                }}>MIS DATOS</Typography>
                  <Typography variant="subtitle1" sx={{ mt: 3}}>
            Aquí encontrarás tus datos personales
        </Typography>
            <Box >
                <TextField
                    margin="normal"
                    disabled
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={datos.mail || ''}
                />
            </Box>
            <Box >
                <TextField
                    margin="normal"
                    disabled
                    fullWidth
                    id="dni"
                    label="DNI"
                    name="dni"
                    autoComplete="dni"
                    value={datos.dni || ''}
                />
            </Box>
            <Box >
                <TextField
                    margin="normal"
                    disabled
                    fullWidth
                    id="name"
                    label="Nombre"
                    name="name"
                    autoComplete="name"
                    value={datos.name || ''}
                />
            </Box>
            <Box >
                <TextField
                    margin="normal"
                    disabled
                    fullWidth
                    id="surname"
                    label="Apellidos"
                    name="surname"
                    autoComplete="surname"
                    value={datos.surname || ''}
                />
            </Box>
            {
              datos.telephone && (
                <Box >
                <TextField
                    margin="normal"
                    disabled
                    fullWidth
                    id="telephone"
                    label="Teléfono"
                    name="telephone"
                    autoComplete="telephone"
                    value={datos.telephone || ''}
                />
            </Box>
              )
            }
            {
              datos.cellphone && (
                <Box >
                <TextField
                    margin="normal"
                    disabled
                    fullWidth
                    id="cellphone"
                    label="Celular"
                    name="cellphone"
                    autoComplete="cellphone"
                    value={datos.cellphone || ''}
                />
            </Box>
              )
            }
           
            <Box >
                <TextField
                    margin="normal"
                    disabled
                    fullWidth
                    id="street"
                    label="Calle"
                    name="street"
                    autoComplete="street"
                    value={datos.street || ''}
                />
            </Box>
            </CardContent>
        </Card>
            </Box>
            
        </Box>
        </>
    )
}

export default MyProfile