import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { FormControl, Grid, Button, Container, Typography  } from '@mui/material';
import { useFormik } from 'formik';
import { Box } from '@mui/system';
import Swal from 'sweetalert2';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import AppNavBar from "../AppBar/AppNavBar";
import { set } from 'date-fns';


const RegisterPrepaids = () => {
  const [prepaids, setPrepaids] = useState([]);
  const [token, setToken] = useState('');
  const [message, setMessage] = useState(null);
  const [allPrepaids, setAllPrepaids] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [pacientes, setPacientes] = useState([]);
  const [userId, setUserId] = useState();
  const [fichaId, setFichaId] = useState();
  const [firstPatient, setFirstPatient] = useState(null);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [isDefault, setIsDefault] = useState();
    const [defaultPrepaidId, setDefaultPrepaidId] = useState(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  
    const marginLeftStyle = isSmallScreen || isMediumScreen || isExtraSmallScreen || isLargeScreen ? { ml: 7 } : {};


  useEffect(() => {
    const storedToken = Cookies.get('token');
    const storedId = Cookies.get('id');
    const name = Cookies.get('name');
    const lastName = Cookies.get('lastname');
    if (storedToken && storedId) {
      setToken(storedToken);
      setUserId(storedId);
      setFirstPatient({name: name, surname : lastName});
    }
  }, []);

  

  useEffect(() => {
    if (token) {
      getAllPrepaids();
      getPacientes();
      getPrepaids();
    }
    
  }, [token]);

  const getPrepaids = async (idPaciente) => {
    const id = idPaciente || fichaId;
    try {
      const response = await axios.get(`${backendUrl}/user/${id}/prepaids/links`, {
        headers: {
          Authorization: token,
        },
      });
      const prepaidsData = response?.data?.data;
    const defaultPrepaid = prepaidsData.find(prepaid => prepaid.is_default === true);
    if (defaultPrepaid) {
      setDefaultPrepaidId(defaultPrepaid.id);
    }

    setPrepaids(prepaidsData);
    } catch (error) {
      setPrepaids([]);
    }
  };

  const getAllPrepaids = async () => {
    try {
      const response = await axios.get(`${backendUrl}/prepaids/all`, {
        headers: {
          Authorization: token,
        },
      });
      if (response.data.data.length > 0) {
        
        setAllPrepaids(response?.data.data);
        
      }
    } catch (error) {
      setAllPrepaids([]);
    }
  }
  const onSubmit = async (values) => {
    
  }
  const formik = useFormik({
    onSubmit,
  });

  const getPacientes = async () => {
    try {
      const response = await axios.get(`${backendUrl}/user/${userId}/patients`, {
        headers: {
          Authorization: token,
        },
      });
      if (response.data?.data.length > 0) {
        getPrepaids(response.data?.data[0].id);
        setPacientes(response.data?.data);
      }
    } catch (error) {
      setPacientes([]);
    }
  };

  const addPrepaid = async(value) => {
    try {
      if (value) {
        const prepaidId = value.id;
       const response =  await axios.post(
          `${backendUrl}/patient/prepaid/link`,
          JSON.stringify({ prepaid_id: prepaidId, ficha_id: fichaId }),
          {
            headers: {
              Authorization: token,
            },
          }
        );
        Swal.fire({
          title: "Agregada!",
          text: "Obra Social Agregada correctamente",
          icon: "success"
        });
        setMessage(response.data.message);
        await getPrepaids();
      }
    } catch (error) {
      Swal.fire({
        title: 'Error al seleccionar la obra social',
        text: error.response.data.message || 'No se pudo seleccionar la obra social',
        icon: 'error',
      })
    }
  };

  const handlePrepaidSelect = (event, value) => {
    
    Swal.fire({
      title: "Agregar obra social",
      text: "Seguro que quieres agregar esta obra social?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        
        addPrepaid(value);
      }
    });
  }

  const defaultPrepaid = async(prepaid) => {
    try {
      const response = await axios.put(
        `${backendUrl}/patient/prepaid/set/default`,
        JSON.stringify({ prepaid_id: prepaid.id, ficha_id: fichaId }),
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setMessage(response.data.message);
      await getPrepaids();

    } catch (error) {
      Swal.fire({
        title: 'Error al establecer como default la obra social',
        text: error.response.data.message || 'No se pudo seleccionar la obra social',
        icon: 'error',
      })
    }
  };

  const handleDefaultPrepaid = async(prepaid) => {
    Swal.fire({
      title: "Establecer como favorita",
      text: "Seguro que quieres establecer esta obra social como favorita?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        setDefaultPrepaidId(prepaid.id);
        defaultPrepaid(prepaid);
      }
    });
  }

  
  useEffect(() => {
    getPrepaids();
  }, [fichaId])

  const handlePatient = (event, value) => {
    setPrepaids([]);
    setFichaId(value.id);
    setSelectedPatient(value);
    setFirstPatient(value);
    
  }

  const deletePrepaid = async (prepaidId) => {
    try {
      const response = await axios.delete(
          `${backendUrl}/patient/prepaid/unlink`,
          {
              data: { prepaid_id: prepaidId, ficha_id: fichaId },
              headers: {
                  Authorization: token,
              }
          }
      );
      setMessage(response.data.message);
      getPrepaids();
  } catch (error) {
      Swal.fire({
          title: 'Error al eliminar obra social',
          text: error.response.data.message || 'No se pudo eliminar la obra social',
          icon: 'error',
      });
  }

  };


  const handleDeletePrepaid = async (prepaidId) => {

    Swal.fire({
      title: "Eliminar obra social",
      text: "¿Estas seguro de eliminar esta obra social?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        deletePrepaid(prepaidId);
      }
    });
  }


  return (
    <>
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />

    <AppNavBar />

    <Box
          component="main"
          sx={{
             textAlign: 'center',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            marginTop: 'auto',
          }}
        >
            <Card variant="outlined"
            sx={{
              width: { xs: "100%", sm: "70%" },
              ml: { xs: "0%", sm: "15%" },
              mt: { xs: "0%", sm: "15%" },
              
                mt: "5%",
                boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.1)',
                transform: 'translateZ(0)',
            }}>
            <CardContent>
            <Typography variant="h5" sx={{ mt: 3, mb: 3}}>
            Modificar obras sociales
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 3}}>
        Aquí podrás agregar, eliminar o establecer como predeterminado una obra social 
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid item >
          
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
              { <Autocomplete
                noOptionsText="No hay pacientes"
                value={firstPatient}
                disableClearable
                id="checkboxes-tags-demo"
                options={pacientes}
                getOptionLabel={(option) => `${option?.name} ${option?.surname}`}
                style={{ width: 500 }}
                onChange={handlePatient}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField {...params} label="Paciente" />}
              />}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
          {
             (
              <Autocomplete
              noOptionsText="No hay obras sociales"
        disableClearable
        id="checkboxes-tags-demo"
        options={allPrepaids}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {option.name}
          </li>
        )}
        style={{ width: 500, overflowX: 'auto' }}
        onChange={handlePrepaidSelect}
        renderInput={(params) => (
          <TextField {...params} label="Obras Sociales" />
        )}
      />
            )
          }
          </Box>
      {
        prepaids !==null && prepaids.length > 0 && 
        <Box sx={{ mt: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Obra social</TableCell>
                <TableCell>Favorita</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prepaids.map((prepaid) => (
                <TableRow key={prepaid.id}>
                  <TableCell>{prepaid.name}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={defaultPrepaidId === prepaid.id}
                      onChange={() => handleDefaultPrepaid(prepaid)}
                    />
                  </TableCell>
                  <TableCell>
                  <IconButton onClick={() => handleDeletePrepaid(prepaid.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
      }


          </Grid>
        </form>
        </CardContent>
        </Card>
        </Box>
        </Box>
    </>
  );
};

export default RegisterPrepaids;