import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemText, Container, Grid, Box, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {formatDate, formatHour} from '../../hooks/utilities';
import { useTheme, lighten } from '@mui/material/styles';
import axios from "../../api/axios";
import Cookies from "js-cookie";
import AppNavBar from '../AppBar/AppNavBar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { set } from 'date-fns';
import ListItemButton from '@mui/material/ListItemButton';




const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Appointments = () => {

const [tableData, setTableData] = useState([]);
const [token, setToken] = useState();
const [userId, setUserId] = useState([]);
const [institutionId, setInstitutionId] = useState([]);
const [pacientes, setPacientes] = useState([]);
const [fichaId, setFichaId] = useState();
const [selectedPatient, setSelectedPatient] = useState();
const [formData, setFormData] = useState({
  isPatientSelected: false,
  isInstitutionSelected: false,
  paciente: null,
  institucion: null,
});
const [loading, setLoading] = useState(false);
const [selectedInstitution, setSelectedInstitution] = useState();
const [instituciones, setInstituciones] = useState([]);
const [openLoader, setOpenLoader] = React.useState(false);


const getPacientes = async () => {
  try {
    const response = await axios.get(`${backendUrl}/user/${userId}/patients`, {
      headers: {
        Authorization: token,
      },
    });
    if (response.data?.data.length > 0) {
      setPacientes(response.data?.data);
    }
  } catch (error) {
    setPacientes([]);
  }
};


const handleClose = () => {
  setOpenLoader(false);
};

const getInstitutions = async () => {
  try {
    const response = await axios.get(`${backendUrl}/institutions`, {
      headers: {
        Authorization: token,
      },
    });
    if (response.data?.data.length > 0) {
      setInstituciones(response.data?.data);
    }
  } catch (error) {
    setInstituciones([]);
  }
};

const handlePatientChange = (event, value) => {
  setSelectedPatient(value);
  setFormData({ ...formData, isPatientSelected: true, paciente: value });
  setTableData([]);
  setSelectedInstitution(null);
};

const handleInstitutionChange = (event, value) => {
  setSelectedInstitution(value);
  setFormData({ ...formData, isInstitutionSelected: true, institucion: value });
  getFichaId(value.id);
  setTableData([]);

};

const getFichaId = async (institutionID) => {
  setOpenLoader(true);
  setLoading(true);
  setFichaId(null);
  setInstitutionId(institutionID);
  try {
    const response = await axios.get(`${backendUrl}/patient/${selectedPatient.dni}/institution/${institutionID}/status`, {
      headers: {
        Authorization: token,
      },
    })
    if(response.data.status_code === 200){
      setOpenLoader(false);
      setLoading(false)
      setFichaId(response.data.data)
      setFormData({ ...formData, isInstitutionSelected: null });
    }
  } catch (error) {
    setOpenLoader(false);
    setLoading(false);
    setFormData({ ...formData, isInstitutionSelected: null });
    console.error("Error al obtener ficha:", error);
  }
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: lighten('#1C7D89', 0.5),
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

//hacer un useEffect que llame a la funcion getNextTurn cuando cambie la fichaId
useEffect(() => {
  if(fichaId){
    getNextTurn(fichaId);
  }
}, [fichaId]);


const getNextTurn = async (ficha) =>{
  // Obtener los proximos turnos utilizando el siguiente endpoint backendUrl/appointments/next, que sea un post usando axios y como body se va a mandar el userId y el institutionId y en headers el token
  try {
    const response = await axios.post(`${backendUrl}/appointments/next`, 
    JSON.stringify({ user_id: ficha, institution_id: institutionId }),
    {
      headers:{
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
    setTableData(response.data.data);
  } catch (error) {
    setTableData([]);
  }
}

const cancelAppointment = async (appointmentId) => {
  try {
    const response = await axios.delete(
      `${backendUrl}/appointment/cancel`,
          {
              data: { appointment_id: appointmentId, institution_id: institutionId },
              headers: {
                  Authorization: token,
              }
          }
    );
    if (response.data.status_code === 200) {
      Swal.fire({
          title: 'Turno cancelado',
          text: 'El turno fue cancelado correctamente',
          icon: 'success',
      });
      getNextTurn(fichaId);
    }
  } catch (error) {
    console.error('Error al eliminar turno:', error);
      Swal.fire({
          title: 'Error al eliminar turno',
          text: error.response.data.message || 'No se pudo eliminar el turno',
          icon: 'error',
      });
  }
};

const hanldeCancelAppointment = (appointmentId) => {
  Swal.fire({
    title: '¿Estás seguro?',
    text: 'No podrás revertir esta acción',
    icon: 'warning',
    iconColor: '#1C7D89',
    showCancelButton: true,
    confirmButtonColor: '#1C7D89',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, cancelar turno',
    cancelButtonText: 'No, mantener turno',
  }).then((result) => {
    if (result.isConfirmed) {
      cancelAppointment(appointmentId);
    }
  });
};



useEffect(() => {
  if (token) {
    getPacientes();
    getInstitutions();
  }
}, [token]);

useEffect(() => {
  // Recuperar el token de las cookies al montar el componente
  const storedToken = Cookies.get("token");
  const storedId = Cookies.get('id');
  if (storedToken) {
    if (!token) {
      setToken(storedToken);
      setUserId(storedId);
    }
  }
}, [setToken, token]);



    return (
        <>
        <Box sx={{ display: 'flex' }}>
        <AppNavBar />
        
    <Box
    component="main"
    sx={{
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
    }}
  >
            <Grid container direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" spacing={2} mt={20} mb={15}>
        <Grid item>
        <Link to="/form">
          <Button variant="contained" color="primary" sx={{ padding: '5px 60px', fontSize: '20px' }}>
            NUEVOS TURNOS
          </Button>
          </Link>
        </Grid>
        <Grid item>
        <Link to="/allappointments">
          <Button variant="contained" color="primary" sx={{ padding: '5px 90px', fontSize: '20px' }}>
            HISTORIAL
          </Button>
          </Link>
        </Grid>
      </Grid>
        
    
    <Box sx={{ width: '100%', justifyContent: "center", pl:2 }}>
    <Typography gutterBottom variant="h3">
        Próximos turnos
    </Typography>
    <Grid container direction={{ xs: 'column', sm: 'row' }} alignItems="center" >
    <Box justifyContent="left" sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2, mr:10 }}>
              <Autocomplete
                noOptionsText="No hay pacientes"
                value={selectedPatient}
                disableClearable
                id="checkboxes-tags-demo"
                options={pacientes}
                onChange={handlePatientChange}
                getOptionLabel={(option) => `${option.name} ${option.surname}`}
                style={{ width: 400, maxWidth: 300 }}
                renderInput={(params) => <TextField {...params} label="Paciente" />}
              />
            </Box>
            {formData.isPatientSelected &&(
              <Box justifyContent="center" sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2, mr:10 }}>
                <Autocomplete
                  noOptionsText="No hay instituciones"
                  value={selectedInstitution}
                  disableClearable
                  id="checkboxes-tags-demo"
                  options={instituciones}
                  onChange={handleInstitutionChange}
                  getOptionLabel={(option) => option.short_name}
                  style={{ width: 400, maxWidth: 300 }}
                  renderInput={(params) => <TextField {...params} label="Institución" />}
                />
              </Box>
            )}
            </Grid>
    
    {
      tableData?.length === 0 || tableData === null ?  (
        <Typography variant="h5" color="text.secondary">
          No hay turnos próximos
        </Typography>
      ):
      <TableContainer component={Paper} sx={{ maxHeight: 440, maxWidth:1500 }}>
        <Table stickyHeader sx={{ minWidth: 500 }}>
        <TableHead>
                  <TableRow>
                      <StyledTableCell>Prestador</StyledTableCell>
                      <StyledTableCell>Obra Social</StyledTableCell>
                      <StyledTableCell>Servicio</StyledTableCell>
                      <StyledTableCell>Fecha</StyledTableCell>
                      <StyledTableCell>Hora</StyledTableCell>
                      <StyledTableCell>Edificio</StyledTableCell>
                      <StyledTableCell>Acciones</StyledTableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {tableData.map((row) => (
                      <StyledTableRow key={row.doctor?.id}>
                          <StyledTableCell>{row?.doctor?.first_name || ""}</StyledTableCell>
                          <StyledTableCell>{row?.os?.name }</StyledTableCell>
                          <StyledTableCell>{row?.service.name}</StyledTableCell>
                          <StyledTableCell>{formatDate(row?.date)}</StyledTableCell>
                          <StyledTableCell>{formatHour(row?.hour_start)}</StyledTableCell>
                          <StyledTableCell>{row?.building}</StyledTableCell>
                          <StyledTableCell>
                          <IconButton onClick={() => hanldeCancelAppointment(row.id)}>
                                <DeleteOutlinedIcon style={{ color: '#1C7D89' }}/>
                            </IconButton>
                          </StyledTableCell>
                      </StyledTableRow>
                  ))}
              </TableBody>
        </Table>
      </TableContainer>
    }
    </Box>
    </Box>
    {loading && 
                <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
                onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              }
              </Box>
        </>
    );
}

export default Appointments;
