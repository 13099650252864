import * as React from 'react';
import { List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, Grid     } from "@mui/material";
import { Box } from "@mui/system";
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Cookies from 'js-cookie';
import HomeIcon from '@mui/icons-material/Home';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import HealingIcon from '@mui/icons-material/Healing';
import PortraitIcon from '@mui/icons-material/Portrait';
import MoodIcon from '@mui/icons-material/Mood';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LogoutIcon from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

export default function NavListDrawer(props) {

  const { handleDrawerOpen, open } = props
  function cerrarSesion() {
    Cookies.remove('token');
    window.location.href = "/login"
}

  const [openTurno, setOpenTurno] = React.useState(false);
  const [openPaciente, setOpenPaciente] = React.useState(false);

  

  const handleClickTurno = () => {
    handleDrawerOpen();
    setOpenTurno(!openTurno);
  };

  const handleClickPaciente = () => {
    handleDrawerOpen();
    setOpenPaciente(!openPaciente);
  };


    return (
        <>
          <nav>
                <List>
                <ListItem disablePadding>
            <ListItemButton
              component="a"
              href="/home"
            >
                <Tooltip title="Inicio" TransitionComponent={Zoom} placement="right">
              <ListItemIcon sx={{ color: 'white' }}>
                <HomeIcon />
              </ListItemIcon>
            </Tooltip>
              <ListItemText primary="Inicio"  sx={{ color: 'white' }}/>
            </ListItemButton>
          </ListItem>
                <ListItemButton onClick={handleClickTurno}>
          <Tooltip title="Turno" TransitionComponent={Zoom} placement="right">
        <ListItemIcon sx={{ color: 'white' }}>
          <DataSaverOnIcon />
        </ListItemIcon>
      </Tooltip>
        <ListItemText primary="Turno" sx={{ color: 'white' }}/>
        {openTurno ? <ExpandLess sx={{ color: 'white' }}/> : <ExpandMore sx={{ color: 'white' }}/>}
      </ListItemButton>
      <Box sx={{ pl: 4 }}>
      <Collapse in={openTurno && open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="/form"
          >
            <ListItemIcon sx={{ color: 'white' }}>
              <FiberNewIcon />
            </ListItemIcon>
            <ListItemText primary="Nuevo Turno" sx={{ color: 'white' }}/>
          </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={openTurno && open} timeout="auto" unmountOnExit sx={{ color: 'white' }}>
        <List component="div" disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="/allappointments"
          >
            <ListItemIcon sx={{ color: 'white' }}>
              <HealingIcon />
            </ListItemIcon>
            <ListItemText primary="Mis turnos" sx={{ color: 'white' }}/>
          </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      </Box>

      <ListItemButton onClick={handleClickPaciente}>
      <Tooltip title="Paciente" TransitionComponent={Zoom} placement="right">
        <ListItemIcon sx={{ color: 'white' }}>
          <PortraitIcon />
        </ListItemIcon>
      </Tooltip>
        <ListItemText primary="Paciente" sx={{ color: 'white' }}/>
        {openPaciente ? <ExpandLess sx={{ color: 'white' }}/> : <ExpandMore sx={{ color: 'white' }}/>}
      </ListItemButton>
      <Box sx={{ pl: 4 }}>
      <Collapse in={openPaciente && open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="/verify-beneficiary"
          >
            <ListItemIcon sx={{ color: 'white' }}>
              <PersonAddAlt1Icon />
            </ListItemIcon>
            <ListItemText primary="Agregar familiar" sx={{ color: 'white' }}/>
          </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={openPaciente && open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="/myprofile"
          >
            <ListItemIcon sx={{ color: 'white' }}>
              <MoodIcon />
            </ListItemIcon>
            <ListItemText primary="Mis datos" sx={{ color: 'white' }}/>
          </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      <Collapse in={openPaciente && open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            component="a"
            href="/register-prepaids"
          >
            <ListItemIcon sx={{ color: 'white' }}>
              <InsertLinkIcon />
            </ListItemIcon>
            <ListItemText primary="Obras sociales" sx={{ color: 'white' }}/>
          </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
      </Box>
      
          
        </List>
      </nav>
      <Divider />
      <Box sx={{ flexGrow: 1 }} />
      <div style={{ marginTop: 'auto' }}>
      <nav aria-label="secondary mailbox folders" >
        <List>
          <ListItem disablePadding>
          <Tooltip title="Cerrar Sesión" TransitionComponent={Zoom} placement="right">
            <ListItemButton
            onClick={cerrarSesion}
            >
              <ListItemIcon sx={{ color: 'white' }}>
              <LogoutIcon />
            </ListItemIcon>
              <ListItemText primary="Cerrar Sesión" sx={{ color: 'white' }}/>
            </ListItemButton>
          </Tooltip>
          </ListItem>
                </List>
            </nav>
      </div>
      
        </>
          
            
    )
}